<template>
  <div class="detail">
    <div class="heade">
      <div>互联网新风向：互联网设计成中国经济增长新动能</div>
      <div class="date">2021.03.26</div>
    </div>
    <div class="text">
      <img src="../../assets/img/news/news_img_1.png" alt="" />
      <p>
        随着中国制造业人口红利逐渐消失，在全球市场需求不足、综合运营成本攀升的外部环境下，数字化转型成为实体经济稳定发展的关键。我国大力推动工业互联网建设，目前具有一定影响力的工业互联网平台超过80个，各种类型的工业APP数量超过35万个，有力支撑了产业提质降本增效。
      </p>
      <p>
        全国政协委员、中国工业互联网研究院院长徐晓兰表示，“制造业作为实体经济与数字经济深度融合的产业，新一代信息技术与制造业深度融合是大势所趋，制造业数字化转型已是势在必行，不做不行，工业互联网恰恰就是实现制造业从中低端迈向中高端的重要保障和支撑。”
      </p>
      <p>
        工业互联网是重塑工业生产制造与服务体系，实现产业数字化、网络化、智能化发展的重要基础设施，基于工业互联网的新产品、新服务不断催生新业态新模式，在一定程度上推动了产业格局在产业体系、产业链供应链、产业空间布局等方面的重构，数字经济进一步向实体经济延伸，推动产业互联网登上新经济发展新赛道。
      </p>
      <img src="../../assets/img/news/news_img_2.png" alt="" />
      <h4>协同式研发新模式，节本又增效</h4>
      <p>
        工业互联网在中小企业数字化转型中发挥了重要作用，不仅能有效降低企业数字化转型成本、降低企业运营成本，提高企业核心竞争力，加速中小企业数字化发展脚步；同时还能推动产业链上下游企业开展协同设计、协同制造、协同供应链管理，实现融通发展生态，助力我国制造业全方位数字化转型。
      </p>
      <p>
        以汽车行业为例，不少整车厂依托PLM平台已成功实现产品的协同设计。例如大众汽车，为了充分利用全球开发资源、制造资源，已构建了多国家、多组织的异地协同设计制造模式。在帕萨特车型开发中，上海大众设计中心仅负责车身和内饰件的开发。
      </p>
      <p>
        橙色云平台所解决的问题，正是大众汽车这般规模企业能轻松达成，但绝大部分中小企业无法跨越的鸿沟，即高额的投入成本、持续的维护成本、优质的研发人才和完善的研发体系等。
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped>
.detail {
  width: 1224px;
  margin: 0 auto;
}
.text {
  font-size: 18px;
  line-height: 36px;
  color: #616161;
  :first-child {
    margin-top: 0px;
  }
  h4 {
    font-size: 20px;
    margin-bottom: 48px;
  }
  p {
    margin: 0;
    margin-bottom: 48px;
  }
  img {
    display: block;
    margin: 90px auto;
  }
}
.heade {
  font-size: 36px;
  text-align: center;
}
.date {
  font-size: 18px;
  color: #2397f3;
  margin: 30px 0;
}
</style>